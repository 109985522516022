@import url('https://use.typekit.net/aeh0dkr.css');

:root {
  --accent-color: #FAAD14;
  --hero-font-color: #FFF;
  --quote-section-color: #5B152E;
  --button-large-font-size: 20px;
}

main {
  font-family: salo-variable, sans-serif;
  font-feature-settings: 'ss01' on;
  font-variation-settings: 'opsz' 1;
}

.heading1 {
  @media (min-width: 768px) {
    font-size: 56px;
    line-height: 110%;
  }
}

/* latin-ext */
@font-face {
  font-family: '__Poppins_637ba7';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/lp/_next/static/media/fe0777f1195381cb-s.p.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_637ba7';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/lp/_next/static/media/eafabf029ad39a43-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_637ba7';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/lp/_next/static/media/c3bc380753a8436c-s.p.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_637ba7';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/lp/_next/static/media/0484562807a97172-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Poppins_Fallback_637ba7';src: local("Arial");ascent-override: 92.33%;descent-override: 30.78%;line-gap-override: 8.79%;size-adjust: 113.73%
}.__className_637ba7 {font-family: '__Poppins_637ba7', '__Poppins_Fallback_637ba7';font-style: normal
}.__variable_637ba7 {--font-poppins: '__Poppins_637ba7', '__Poppins_Fallback_637ba7'
}

